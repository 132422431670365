import React from 'react'
import styled from 'styled-components'

export const InputGroup = styled.div`
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ; */
  /* grid-gap: 24px; */

  @media (max-width: 500px) {
    padding: 24px 12px 0 12px;
  }
`;

export const InputSectionTitle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 0 0 12px 0;
`

export const SectionLabel = styled.h3`
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0 12px 0px 0;
  font-weight: 500;
  display: flex;
  flex-wrap: nowrap;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

export const HorizontalLine = styled.div`
  border: 0.5px solid ${ props => props.theme.theme.border.primary};
  margin: 24px 0 24px ;
  width: 100%;
`

export const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
  width: 100%;
`;

export const InputPanel = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  margin: 0 0px 0px 0;
  width: 100%;

  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 4px;
  align-content: center;

  background-color: ${(props) => props.theme.theme.bg.tertiary};

  .suffix {
    display: flex;
    align-self: center;
  }

  @media (max-width: 500px) {
    grid-template-columns: auto;
  }
`

export const InputLabel = styled.h3`
  margin: auto 0px auto 0;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

export const InputItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;

  @media (max-width: 500px) {
    margin: 0 0 8px 0;
  }
`;
